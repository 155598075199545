<template>
  <div class="app-container">
    <div>
    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%"
      size="mini"
    >
      <el-table-column label="用户编号" width="100" align="center">
        <template slot-scope="{row}">
          <span>{{ row.userid }}</span>
        </template>
      </el-table-column>
      <el-table-column label="登录IP" align="center">
        <template slot-scope="{row}">
          <span>{{ row.ipaddr }}</span>
        </template>
      </el-table-column>
      <el-table-column label="登录时间" align="center" width="130">
        <template slot-scope="{row}">
          <span>{{ row.logindate | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
    </el-table>
    </div>
  </div>
</template>

<script>
  import {getloginRecordList} from "@/apis";
  import {parseTime} from '@/utils'

  export default {
    name: 'projectinfo',
    components: {
      pagination: () => import('@/components/common/PagingPlug'),
    },
    filters: {
      parseTime: parseTime
    },
    data() {
      return {
        tableKey: 0,
        list: null,
        total: 0,
        listLoading: true,
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.listLoading = true
        let data = {
          csid: this.user.userId
        }
        getloginRecordList(data).then(res => {
          const {data = [], count = 0} = res;
          this.list = data;
          this.listLoading = false;
          this.$emit('loginRecord',this.list.length)
        })
      },
      formatJson(filterVal) {
        return this.list.map(v => filterVal.map(j => {
          if (j === 'timestamp') {
            return parseTime(v[j])
          } else {
            return v[j]
          }
        }))
      }
    }, computed: {}
  }
</script>

<style>
  .filter-item {
    margin-left: 5px;
  }

  .el-dialog__footer {
    text-align: center;
  }

  .el-tooltip__popper {
    max-width: 800px;

  }

  .app-top {
    position: fixed;
    width: 100%;
    z-index: 10;
    top: 0;
  }

  .app-title {
    height: 50px;
    width: 100%;
    background-color: #fa8c16;
    font-size: 15px;
    color: white;
    text-align: center;
    line-height: 50px;
    font-weight: 600;
  }

  .returnIcon {
    font-size: 19px;
    color: white;
    line-height: 50px;
    font-weight: 600;
    position: absolute;
    left: 12px;
  }


</style>
