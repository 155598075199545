<template>
  <el-container class="server-container" style="height: 100%;">
    <el-header class="header" :style="{backgroundColor: framecolor}">
      <div class="flex fc-white" style="width: 30%">
        <div style="font-size: 30px"><img height="26px" :src="$store.state.setting.registerpageimage"></div>
        <div class="mg-l10" style="font-size: 25px;">默蓝智能客服系统</div>
        <div v-if="logintype==1" class="mg-l10" style="font-size: 18px;">
          <router-link to="/server">客服工作台</router-link>
        </div>
      </div>
      <div style="position: absolute;right: 30px;z-index: 10;top: 0px;">
        <div style="display: flex;color: white;font-size: 14px;line-height: 40px">
<!--          <div class="mar-r10">状态：<span>在线</span></div>-->
          <div class="mar-r10">账号：<span>{{ servInfo.account }}</span>
            <span v-if="isOnline">(在线)</span>
            <span v-else-if="!isOnline && healthStatus!==1">(异常)</span>
            <span v-else-if="!isOnline && healthStatus===1">(离线)</span>
          </div>
          <div class="mar-r10">用户名：<span>{{ servInfo.username }}</span>
          </div>
          <div class="online"><span class="mar-r11" :class="{'green':isOnline,'yellow':!isOnline}"></span>
            <a @click="moveout()">
              {{ isOnline ? '客服在线: ' : '网络异常' }}
            </a>
            <!--            <span v-if="isOnline" v-model="heartbeatIntervalTime">{{ heartbeatIntervalTime }}s</span>-->
            <span v-if="isOnline" style="width: 28px">{{ heartbeatIntervalTime }}s</span>
          </div>
          <div class="mar-r10" v-if="!lineStatus"><a @click="movein()">客服已迁出</a></div>
          <el-button class="top-btn top-btn" v-if="lineStatus"><a @click="moveout()">签出</a></el-button>
          <el-button class="top-btn" v-if="!lineStatus"><a @click="movein()">签入</a></el-button>
          <el-button class="top-btn"><a @click="logout()">退出登录</a></el-button>
        </div>
      </div>
      <div style="position: absolute;right: 30px;z-index: 10;top: 20px;line-height: 50px">
        <div style="display: flex;color: white;font-size: 14px;">
          <div class="mar-r10">客服登录时间：<span>{{ statisticsVo.lastlogintime }}</span></div>
          <div class="mar-r10">当前连接数：<span>{{ statisticsVo.nowcount }}</span></div>
          <div class="mar-r10">今日总连接数：<span>{{ statisticsVo.daycount }}</span></div>
          <div class="mar-r10">总消息数：<span>{{ statisticsVo.notreadcount }}/{{ statisticsVo.yesreadcount }}</span>
          </div>
        </div>
      </div>
    </el-header>
    <el-container style="height: calc(100% - 60px)">
      <el-aside width="300px">
        <el-main style="height: 25%">
          <div style="height: 100%;">
            <div style="font-weight: 600;text-align: center;padding: 10px;background-color: #f5f5f5">当前对话列表</div>
            <div v-if="groupList.length==0" class="noList">暂无当前对话</div>
            <el-scrollbar style="height: calc(100% - 40px)">
              <ul style="font-size: 12px;line-height: 30px;">
                <el-menu
                  :default-active="current"
                  class="el-menu-vertical-demo">
                  <template v-for="(item,len) in groupList">
                    <el-submenu :index="len+''">
                      <template slot="title" >
                          <span>{{ item.projname }}</span><span v-if="item.msgCount > 0"
                                                                class="msgCount">{{ item.msgCount }}</span>
                      </template>
                      <el-menu-item-group>
                        <template v-for="(msg,a) in onlineconnList">
                          <el-menu-item :class="msg.status !== '1' ? 'notline':''" v-if="msg.projname===item.projname"
                                        :index="msg.id+''">
                            <div @click="showmsg(msg,msg.id+'')" style="display: flex">
                              <div style="width: 54%;">
                                <div>(ID{{ msg.id }}) {{ msg.ip }}</div>
                              </div>
                              <div style="width: 40%;">
                                {{ msg.createtime | parseTime('{y}-{m}-{d} {h}:{i}') }}
                              </div>
                              <div v-if="msg.notreadcount > 0" class="msgCount" style="right: 4px">
                                {{ msg.notreadcount }}
                              </div>
                            </div>
                          </el-menu-item>
                        </template>
                      </el-menu-item-group>
                    </el-submenu>
                  </template>
                </el-menu>
              </ul>
            </el-scrollbar>
          </div>
        </el-main>
<!--        匿名分组-->
        <el-main style="height: 25%">
          <div style="height: 100%;">
            <div style="font-weight: 600;text-align: center;padding: 10px;background-color: #f5f5f5">匿名对话列表</div>
            <div v-if="anonymityGroup.length==0" class="noList">暂无匿名对话</div>
            <el-scrollbar style="height: calc(100% - 40px)">
              <ul style="font-size: 12px;line-height: 30px;">
                <el-menu
                  :default-active="current"
                  class="el-menu-vertical-demo">
                  <template v-for="(item,len) in anonymityGroup">
                    <el-submenu :index="len+''">
                      <template slot="title" >
                        <span>{{ item.projname }}</span><span v-if="item.msgCount > 0" class="msgCount">{{ item.msgCount }}</span>
                      </template>
                      <el-menu-item-group>
                        <template v-for="(msg,a) in anonymityList">
                          <el-menu-item :class="msg.status !== '1' ? 'notline':''" v-if="msg.projname===item.projname"
                                        :index="msg.id+''">
                            <div @click="showmsg(msg,msg.id+'')" style="display: flex">
                              <div style="width: 54%;">
                                <div>(ID{{ msg.id }}) {{ msg.ip }}</div>
                              </div>
                              <div style="width: 40%;">
                                {{ msg.createtime | parseTime('{y}-{m}-{d} {h}:{i}') }}
                              </div>
                              <div v-if="msg.notreadcount > 0" class="msgCount" style="right: 4px">
                                {{ msg.notreadcount }}
                              </div>
                            </div>
                          </el-menu-item>
                        </template>
                      </el-menu-item-group>
                    </el-submenu>
                  </template>
                </el-menu>
              </ul>
            </el-scrollbar>
          </div>
        </el-main>


        <el-main style="height: 50%">
          <div style="font-weight: 600;text-align: center;padding: 10px;border-bottom: 1px solid #d7d5d5;background-color: #f5f5f5">历史对话列表</div>
          <div v-if="historygroupList.length==0" class="noList">暂无历史对话</div>
          <el-scrollbar style="height: calc(100% - 41px)">
            <ul style="font-size: 12px;line-height: 30px;">
              <el-menu
                :default-active="current"
                class="el-menu-vertical-demo">
                <template v-for="(item,len) in historygroupList">
                  <el-submenu :index="len+''">
                    <template slot="title">
                      <span>{{ item.projname }}</span>
                    </template>
                    <el-menu-item-group>
                      <template v-for="(msg,a) in historyconnList">
                        <el-menu-item :class="msg.status != '1' ? 'notline':''" v-if="msg.projname==item.projname"
                                      :index="msg.id+''">
                          <div @click="showmsg(msg,msg.id+'')" style="display: flex">
                            <div style="width: 54%;">
                              <div>(ID{{ msg.id }}) {{ msg.ip }}</div>
                            </div>
                            <div style="width: 40%;">
                              {{ msg.createtime | parseTime('{y}-{m}-{d} {h}:{i}') }}
                            </div>
                          </div>
                        </el-menu-item>
                      </template>
                    </el-menu-item-group>
                  </el-submenu>
                </template>
              </el-menu>
            </ul>
          </el-scrollbar>
        </el-main>
      </el-aside>
      <el-container style="height: 100%;">
        <el-header
          style="width:100%;height:170px;padding: 0;border-bottom: 1px solid #e8e6ea;background-color: white;">
          <el-scrollbar
            style="width: calc(100% - 100px);float: left;overflow-y: auto; height: 100%;">
            <el-table :key="tableKey"
                      :data="clientConnList"
                      border
                      fit
                      highlight-current-row
                      size="mini">
              <el-table-column label="项目名称" align="center" show-overflow-tooltip >
                <template slot-scope="{row}">
                  <span>{{ row.projname }}</span>
                </template>
              </el-table-column>
              <el-table-column label="IP地址" align="center"  width="140px">
                <template slot-scope="{row}">
                  <span>{{ row.ip }}</span>
                </template>
              </el-table-column>
              <el-table-column label="地址" align="center" show-overflow-tooltip>
                <template slot-scope="{row}">
                  <span>{{ row.address }}</span>
                </template>
              </el-table-column>
              <el-table-column label="用户连接名" show-overflow-tooltip align="center">
                <template slot-scope="{row}">
                  <span>{{ row.clientname }}</span>
                </template>
              </el-table-column>
              <el-table-column label="连接状态" align="center" width="80px">
                <template slot-scope="{row}">
                  <span v-if="row.status ==1">{{ row.status | statusFilter }}</span>
                  <span v-if="row.status !=1"><div style="color: red">{{ row.status | statusFilter }}</div></span>
                </template>
              </el-table-column>
              <el-table-column label="连接开始时间" align="center" width="150px">
                <template slot-scope="{row}">
                  <span>{{ row.createtime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                </template>
              </el-table-column>
              <el-table-column label="时长" align="center" width="180px">
                <template slot-scope="{row}">
                  <span v-if="row.status==1"><timer :start="row.createtime"></timer> </span>
                  <span v-if="row.status !=1">{{ row | formatDate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="最后心跳" align="center" width="80px">
                <template slot-scope="{row}">
                  <span>{{ row.clientheartbeattime | parseTime('{h}:{i}') }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-scrollbar>
          <div
            style="width: 100px;font-size: 12px;float: right;border: 1px solid #eae9e9;height: 170px;text-align: center;color: #909399;">
            <div style="padding: 9px;border-bottom: 1px solid #eae9e9;">心跳列表</div>
            <div style="height: calc(100% - 35px);">
              <el-scrollbar style="height: 100%">
                <template v-for="(item,index) in heartbeatLists">
                  <div v-if="item.type===1" style="padding-top: 5px">{{ item.heartbeattime }} 正常</div>
                  <div v-if="item.type===0" style="padding-top: 5px;color: red">
                    <i class="el-icon-error"></i>{{ item.heartbeattime }} 异常
                  </div>
                </template>
              </el-scrollbar>
            </div>
          </div>
        </el-header>
        <el-container style="background-color: white;height: calc(100% - 170px)" >
          <el-main class="task-left" style="height: 100%;position: relative" >
            <div v-if="current" class="custominfo">
              <div style="display: flex;width: calc(100% - 40px);overflow: hidden;">
                <div class="mar-l10">项目名：{{ conninfo.projname }}</div>
                <div class="mar-l10" v-if="conninfo.nicename">昵称：{{ conninfo.nicename }}</div>
                <div class="mar-l10">IP：{{ conninfo.ip}}</div>
                <div class="mar-l10">地区：{{ conninfo.address }}</div>
                <div class="mar-l10">SID:{{conninfo.clientid}}</div>
                <a @click="updateUserInfo" style="position: absolute;right: 10px;font-size: 14px"
                   class="mar-l10" v-if="conninfo.address"><i class="el-icon-edit-outline">客户信息</i></a>
              </div>
            </div>
            <div class="message-record">
              <el-scrollbar id="msgList" style="height: 100%;" ref="selfScrollbar">
                <template v-for="item in messageConfig.data">
                  <div :class="['message-row',{self: item.type==1}]">
                    <div>
                      <span class="mar-r10">{{ item.nickname }}</span>
                      <span>{{ dateToString(item.sendTime) }}</span>
                    </div>
                      <div class="message-content mar-t10" style="position: relative">
                        <div style="position: absolute;left: -30px;color: #999999" v-if="item.type==1 ">
                          <span v-if="item.adminreadflag===0 || item.adminreadflag==='0'">未读</span>
                        </div>
                        <div style="position: absolute;left: -30px;color: #999999" v-if="item.type==1">
                          <span v-if="item.adminreadflag===1 || item.adminreadflag==='1'">已读</span>
                        </div>
                        <div v-html="item.content"></div>
                      </div>
                  </div>
                </template>
              </el-scrollbar>
            </div>
            <editor @send="webSocketConfig.onSend"
                    v-model="content"
                    placeholder="按 Enter 发送"
                    v-if="current"
                    @toggleSound="onToogleSound"
            >
            </editor>
          </el-main>
          <el-main class="task-right">
            <el-tabs v-model="activeName" @tab-click="handleClick" style="height: 100%;">
              <el-tab-pane label="快捷回复" name="first">
                <div style="height: 100%">
                  <div style="font-weight: 600;font-size: 14px">我的常用语</div>
                  <div>
                    <el-input
                      placeholder="输入关键字进行过滤"
                      v-model="filterText">
                    </el-input>
                    <el-tree
                      show-checkbox
                      check-strictly
                      node-key="id"
                      ref="tree"
                      :data="quickReplyList"
                      :props="defaultProps"
                      :filter-node-method="filterNode"
                      @node-click="handleNodeClick"></el-tree>
                  </div>
                  <div style="position: absolute;bottom: 0px;right: 6%;">
                    <el-dropdown style="margin-right: 0px">
                      <el-button type="success" size="mini">
                        新增<i class="el-icon-circle-plus-outline el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="addGroup">添加分组</el-dropdown-item>
                        <el-dropdown-item @click.native="addQuickReply">添加常用语</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-button size="mini" type="primary" icon="el-icon-edit" @click="updateDate()">
                      修改
                    </el-button>
                    <el-button size="mini" type="danger" icon="el-icon-delete"
                               style="margin-left:0px"
                               @click="deleteDate()">删除
                    </el-button>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane :label="'留言管理'+'('+this.leaveMessageNumber+')'" name="second">
                <div style="font-weight: 600;font-size: 14px">用户留言</div>
                <LeaveMessage @leaveMessage="leaveMessageCount" ref="leaveMessage"></LeaveMessage>
              </el-tab-pane>
              <el-tab-pane :label="'项目信息'+'('+this.projectListNumber+')'" name="three">
                <ProjectList @projectList="projectListCount"></ProjectList>
              </el-tab-pane>
              <el-tab-pane :label="'登录日志'+'('+this.loginRecordNumber+')'" name="four">
                <LoginRecord @loginRecord="loginRecordCount"></LoginRecord>
              </el-tab-pane>
            </el-tabs>
          </el-main>
        </el-container>
      </el-container>
    </el-container>

    <!-- 添加或者修改分组弹框 -->
    <el-dialog :title="textGroupMap[dialogStatus]" :visible.sync="dialogGroupVisible">
      <el-form ref="dataForm" :rules="Grouprules" :model="GroupForm" label-position="left"
               label-width="100px" style="width: 500px; margin-left:50px;">
        <el-form-item label="分组名称" prop="groupname">
          <el-input v-model="GroupForm.groupname" type="text" placeholder="请输入分组名称"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogGroupVisible = false">
          取消
        </el-button>
        <el-button type="primary"
                   @click="dialogStatus==='create'?createGroupData():updateGroupData()">
          保存
        </el-button>
      </div>
    </el-dialog>

    <!-- 添加或者修改快捷常用语 -->
    <el-dialog :title="textQuickReplyMap[dialogStatus]" :visible.sync="dialogQuickReplyVisible">
      <el-form ref="dataForm" :rules="QuickReplyrules" :model="QuickReplyForm" label-position="left"
               label-width="100px" style="width: 500px; margin-left:50px;">
        <el-form-item label="选择分组" prop="groupid">
          <el-select v-model="QuickReplyForm.groupid" placeholder="请选择分组" clearable
                     style="width: 150px">
            <template v-for="group in allGroupList">
              <el-option :label="group.groupname" :value="group.groupid"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="快捷标题" prop="title">
          <el-input v-model="QuickReplyForm.title" type="text" placeholder="请输入快捷标题"/>
        </el-form-item>
        <el-form-item label="快捷内容" prop="content">
          <el-input v-model="QuickReplyForm.content" type="textarea" placeholder="请输入快捷内容"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogQuickReplyVisible = false">
          取消
        </el-button>
        <el-button type="primary"
                   @click="dialogStatus==='create'?createQuickReplyData():updateQuickReplyData()">
          保存
        </el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="imgsVisible" width="80%">
      <div style="display: flex;justify-content: center;">
        <el-image :src="imgs" fit="scale-down" lazy style="margin: 10px auto;max-width: 90%">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
    </el-dialog>

    <!--  输入用户身份 dialog  -->
    <el-dialog
      title="客户信息"
      center
      :visible.sync="identityConfig.show"
      width="500px" class="customerInfo">
      <el-form :model="identityConfig.form"
               :rules="identityConfig.formRules"
               ref="identityForm"
      >
        <el-form-item prop="area">
          <el-input v-model="identityConfig.form.nicename" placeholder="昵称"></el-input>
        </el-form-item>
        <el-form-item prop="area">
          <el-input v-model="identityConfig.form.address" placeholder="地区"></el-input>
        </el-form-item>
        <el-form-item prop="gender">
          <el-radio-group v-model="identityConfig.form.gender">
            <el-radio label="M">男</el-radio>
            <el-radio label="F">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="age">
          <el-input v-model="identityConfig.form.age" placeholder="年龄"></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input v-model="identityConfig.form.phone" placeholder="电话"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="identityConfig.form.remarks" type="textarea" rows="3"
                    placeholder="请输入备注"/>
        </el-form-item>
        <div class="tc">
          <el-button @click="onSubmitClick('identityForm')" type="primary"
                     size="mini">提交
          </el-button>
        </div>
      </el-form>
    </el-dialog>

  </el-container>
</template>


<script>
import editor from '@PAGE/common/ChatArea'
import {parseTime} from '@/utils'
import Timer from '../components/Timer'
import LeaveMessage from '../components/LeaveMessage'
import ProjectList from '../components/ProjectList'
import LoginRecord from '../components/LoginRecord'
import {
  getDataList,
  queryallMsg,
  querynotdealMsg,
  sendOneWebSocket,
  getHisoryConnectList,
  addOrUpdateUserInfo,
  freshAdminReadFlag,
  sendOnlineMessage
} from "@/apis/webstocket";
import config from "@/resources/js/config";
import {
  addorupdategroup,
  addorupdateQuickreplyInfo,
  deleteGroup,
  deleteQuickreplyInfo,
  getQuickreply
} from "@/apis/quick-reply";
import asyncValidator from "@/resources/js/async-validator";

const statusOptions = [
  {key: '1', display_name: '连接'},
  {key: '2', display_name: '断开'},
  {key: '3', display_name: '断开'},
]
const msgflagOptions = [
  {key: '0', display_name: '无'},
  {key: '1', display_name: '有新消息'},
]
const calendarTypeKeyValue = statusOptions.reduce((acc, cur) => {
  acc[cur.key] = cur.display_name
  return acc
}, {})
const msgflagTypeKeyValue = msgflagOptions.reduce((acc, cur) => {
  acc[cur.key] = cur.display_name
  return acc
}, {})
export default {
  name: "index",
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  components: {
    Editor: editor,
    Timer,
    LeaveMessage,
    ProjectList,
    LoginRecord,
  },
  filters: {
    statusFilter(type) {
      return calendarTypeKeyValue[type]
    },
    msgflagFilter(type) {
      return msgflagTypeKeyValue[type]
    }, formatDate(row) {
      let mss = row.disconnecttime - row.createtime;

      const seconds = Math.floor((mss / 1000) % 60);
      const minutes = Math.floor((mss / (1000*60)) % 60)
      const hours = Math.floor((mss / (1000*60*60)) % 24)
      let timeString = '';
      if (hours > 0){
        timeString +=hours + 'h'
      }
      if (minutes > 0 || (hours>0 && seconds >0)){
        timeString += minutes + 'm'
      }

      if (seconds >0 || (minutes >0 && hours ===0)){
        timeString +=seconds + 's';
      }
      return timeString;
    },
    parseTime: parseTime,
  },
  data() {
    return {
      isOnline: false,
      heartbeatIntervalTime: this.$store.state.setting.heartbeatIntervalTime,// 10 秒一次心跳
      OnlineTimeoutObj: null,// 客服在线心跳倒计时
      onLine: navigator.onLine,
      framecolor: '#da424e',
      isPlaySound: true,
      leaveMessageNumber:0,
      projectListNumber:0,
      loginRecordNumber:0,
      lineStatus: true,
      windowTitle: window.top.document.title,
      imgs: "",
      imgsVisible: false,
      groupList: [],//今天分组
      anonymityGroup:[],//匿名分组
      historygroupList: [],//历史分组
      conninfo: {},
      csheartbeattime: parseTime(new Date()),
      current: null,
      heartbeatList: [],
      conncount: 0,
      tableKey: 0,
      logintype: 1,
      userId: "",
      toUserId: "",
      projid: "",
      onlineconnList: null,
      historyconnList: null,
      anonymityList:null, //匿名列表
      statisticsVo: {
        lastlogintime: '',
        notreadcount: 0,
        yesreadcount: 0,
        daycount: 0,
        nowcount: 0
      },
      clientConnList: null,
      allGroupList: [],
      servInfo: {
        csid: "",
        account: "",
        username: "思软客服",
        state: null
      },
      content: null,
      messageConfig: {
        data: [],
        loadData: () => {
        },
      },
      webSocketConfig: {
        webSocket: null,
        open: () => {
        },
        onSend: (msg) => {
          if (!this.lineStatus) {
            this.$message.info("客服已迁出，不能发送消息")
            return false;
          }
          if (!this.isOnline && this.healthStatus!==1){
            this.$message.info("网络异常，发送失败，请稍后再试！")
            return false;
          }
          this.content = null;
          let message = {
            type: 1,
            serviceflag: 1,
            connid: this.connid,
            userId: this.userId,
            content: msg,
            projid: this.projid,
            nickname: '我',
            msgtype: 2,
            adminreadflag:0,
            clientid: this.conninfo.clientid,
            toUserId: this.toUserId,
            sendTime: new Date().getTime(),
          };
          console.log("serverMessage:",message)
          if (this.current != null) {
            sendOneWebSocket(message).then(res=>{
              if (res.retdata){
                message.adminreadflag = 1;
              }
              this.messageConfig.data.push(message);
            })
          }
        }
      },
      healthStatus:1,  //健康状态（0异常，1在线，离线）
      lockReconnect: false,
      timeout: 60 * 1000,//58秒一次心跳
      timeoutObj: null,//心跳倒计时
      serverTimeoutObj: null,//心跳倒计时
      timeoutnum: null,//断开 重连倒计时
      timer: null, // 秒表计时器
      filterText: null,
      activeName: 'first',
      dialogGroupVisible: false,
      dialogQuickReplyVisible: false,
      dialogStatus: '',
      textGroupMap: {
        update: '修改分组',
        create: '添加分组'
      },
      textQuickReplyMap: {
        update: '修改快捷常用语',
        create: '添加快捷常用语'
      }, quickReplyList: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      Grouprules: {
        groupname: [{required: true, message: '请输入分组名称', trigger: 'change'}]
      },
      GroupForm: {
        csid: null,
        groupid: null,
        groupname: null
      },
      QuickReplyrules: {
        groupid: [{required: true, message: '请选择分组', trigger: 'change'}],
        title: [{required: true, message: '请输入快捷标题', trigger: 'change'}],
        content: [{required: true, message: '请输入快捷内容', trigger: 'change'}]
      },
      QuickReplyForm: {
        id: null,
        groupid: null,
        content: null,
        title: null
      },
      selectTree: [],
      identityConfig: {
        show: false,
        form: {
          gender: null,
          nicename: null,
          age: null,
          phone: null,
          address: null,
          clientid: null,
          remarks: null,
          id: null,
        }
      }, formRules: {
        nicename: [
          {required: true, message: '请输入昵称', trigger: ['change', 'blur']},
        ],
        address: [
          {required: true, message: '请输入地区', trigger: ['change', 'blur']},
        ],
        gender: [
          {required: true, message: '请选择性别', trigger: ['change', 'blur']},
        ],
        age: [
          {validator: asyncValidator.validAge, message: '年龄范围不正确', trigger: ['change', 'blur']},
        ],
        phone: [
          {validator: asyncValidator.validPhone, message: '手机号不正确', trigger: ['change', 'blur']},
        ]
      }
    }
  },
  mounted() {
    this.getDataList();
    this.getHisoryConnectList();
    this.servInfo.csid = this.user.userId;
    this.servInfo.account = this.user.account;
    this.servInfo.username = this.user.username;
    this.userId = 'csService-' + this.user.userId;
    this.webSocketConfig.open();
    this.heartbeatList.push(parseTime(new Date()))
    this.getQuickreply();
    this.initWebSocket();
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.isoffline();
  },
  updated() {
    // 聊天定位到底部
    /*  let ele = document.getElementById('msgList');
      ele.scrollTop = ele.scrollHeight;*/
    this.selfScrollDown()
  }, beforeDestroy() {
    this.clear()
    this.onbeforeunload()
  },
  created() {
    window.imgView = this.imgView
    // 开启长连接
    //this.initWebSocket();
  },
  computed: {
    heartbeatLists: function () {
      this.heartbeatList = this.heartbeatList.reverse();
      if (this.heartbeatList.length > 100) {
        this.heartbeatList = this.heartbeatList.slice(0, 99)
        return this.heartbeatList
      } else {
        return this.heartbeatList;
      }
    }
  },
  methods: {
    leaveMessage(){
      console.log(77)
    },
    leaveMessageCount(count){
      this.leaveMessageNumber = count
    },
    projectListCount(count){
      this.projectListNumber = count
    },
    loginRecordCount(count){
      this.loginRecordNumber = count
    },
    onToogleSound(state) {
      this.isPlaySound = state;
    },
    getDataList() {
      let this_ = this;
       // this.groupList = [];
      // this.onlineconnList = [];
      // this.clientConnList = [];
      this.servInfo.csid = this.user.userId;
      getDataList(this.servInfo).then(res => {
        if (res.retdata.onlineconnList != null) {
          this.groupList = res.retdata.onlineconngroup;
          this.onlineconnList = res.retdata.onlineconnList;
          this.anonymityList = res.retdata.anonymityList
          this.anonymityGroup = res.retdata.anonymityGroup
          this.clientConnList = res.retdata.connList;
          this.statisticsVo = res.retdata.statisticsVo;
          this.conncount = res.retdata.onlineconnList.length;
        } else {
          this.current = null;
          this.messageConfig.data = [];
        }
      })
    },
    getHisoryConnectList() {//获取历史连接
      this.servInfo.csid = this.user.userId;
      getHisoryConnectList(this.servInfo).then(res => {
        if (res.retdata != null) {
          this.historygroupList = res.retdata.historygroupList;
          this.historyconnList = res.retdata.historyconnList;
        }
      })
    },
    initWebSocket() {
      if (this.websocket !=null && this.websocket.readyState === WebSocket.OPEN){
        this.healthStatus = this.websocket.readyState
        this.isOnline = true;
        return
      }
      if ('WebSocket' in window) {
        let appPath = window.location.origin;

        if (appPath) {
          if (appPath.indexOf("https") == -1) {
            appPath = appPath.replaceAll("http", "ws");
          } else {
            appPath = appPath.replaceAll("https", "wss");
          }
        }

        // this.websocket = new WebSocket('ws://localhost:8081/websocket/' + this.userId);
        this.websocket = new WebSocket(appPath + `${config.BASE_URL}/websocket/${this.userId}`)
        // this.websocket = new WebSocket(appPath + `${config.BASE_URL}/websocket/c1111`)
        //console.log(this.websocket)
        // 连接错误
        this.websocket.onerror = this.setErrorMessage
        // 连接成功
        this.websocket.onopen = this.setOnopenMessage
        // 收到消息的回调
        this.websocket.onmessage = this.setOnmessageMessage
        // 连接关闭的回调
        this.websocket.onclose = this.setOncloseMessage
        // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
        window.onbeforeunload = this.onbeforeunload
      } else {
        alert('当前浏览器 Not support websocket')
      }
    },
    setErrorMessage() {
      console.log('WebSocket连接发生错误状态码：' + this.websocket.readyState)
      this.isOnline = false;
      this.healthStatus = this.websocket.readyState
    },
    setOnopenMessage() {
      console.log('WebSocket连接成功状态码：' + this.websocket.readyState)
      //维护客服在线状态
      this.healthStatus = this.websocket.readyState
      this.isOnline = true;
      this.startHeartbeat();

      let message = {
        csid: this.servInfo.csid,
        msgtype: 4,
      };

      setTimeout(function () {
        sendOneWebSocket(message).then()
      }, 2000)

      this.start();//开启心跳
    },
    setOnmessageMessage(event) {
      let content = event.data;
      //消息解析
      const message = JSON.parse(content);
      console.log(message)
      var msgtype = message.msgtype;
      console.log("---------------------->",msgtype)
      if (msgtype == 1 || msgtype == 6 || msgtype == 5) {
        if (msgtype == 5 ){
          this.freshAdminReadFlag(message.clientid,message.projid)
        }
        // 根据服务器推送的消息做自己的业务处理
        if (this.current != null) {
          let data = {
            id: this.connid
          }
          let _this = this;
          let this_ = this.messageConfig;
          querynotdealMsg(data).then(res => {
            if (res.retdata != null) {
              res.retdata.forEach(function (item, index) {
                let type = 0;
                let nickname = item.projname;
                if (item.msgflag == 2) {
                  type = 1;
                  nickname = '我';
                }
                let message = {
                  type: type,
                  content: item.content,
                  nickname: nickname,
                  sendTime: item.createtime,
                };
                this_.data.push(message);
                if (msgtype === 5 ){
                  for (let i = 0; i < this_.data.length; i++) {
                    if (this_.data[i].type === 1 && this_.data[i].adminreadflag===0){
                      this_.data[i].adminreadflag = 1
                    }
                  }
                }
              })
            }
          })

        }
        this.getDataList();
        if (msgtype == 1 && this.isPlaySound) {
          let audio = new Audio()
          audio.src = "/audio/msg.mp3"
          audio.play();
        }
        if (msgtype == 5 && this.isPlaySound) {
          let audio = new Audio()
          audio.src = "/audio/newconnect.mp3"
          audio.play();
        }
      }
      //账号重复登录，强制退出
      if (msgtype == 10) {
        this.websocket.close();
        this.$alert(message.content, '异地登录提醒', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.push({path: '/login'})
          }
        });
      }
      if (msgtype == 8) {//客服心跳
        //收到服务器心跳信息，心跳重置
        this.reset();
      }
    },
    freshAdminReadFlag(clientid,projid){
      let params={
        csid:this.servInfo.csid,
        clientid:clientid,
        projid:projid
      }
      console.log(params)
      freshAdminReadFlag(params).then()
    },
    setOncloseMessage() {
      console.log('WebSocket连接关闭 状态码：' + this.websocket.readyState)
      this.healthStatus = this.websocket.readyState
      this.isOnline = false;

      //维护客服在线状态 发送客服断线消息
      console.log("userID" + this.servInfo.csid)
      clearTimeout(this.timeoutObj);//清除心跳
      let message = {
        connid: this.connid,
        csid: this.servInfo.csid,
        msgtype: 3,
      };
      try {
        sendOneWebSocket(message).catch(e => {

        })

      } catch (e) {

      }


    },
    onbeforeunload() {
      this.closeWebSocket()
    },
    closeWebSocket() {
      this.websocket.close()
    }, formatJson(filterVal) {
      return this.list.map(v => filterVal.map(j => {
        if (j === 'timestamp') {
          return parseTime(v[j])
        } else {
          return v[j]
        }
      }))
    },
    showmsg(info, index) {
      this.conninfo = info;
      this.current = index;
      this.messageConfig.data = [];
      this.toUserId = info.clientname;
      this.connid = info.id;
      this.projid = info.projid;
      var _this = this;
      let this_ = this.messageConfig;
      queryallMsg(info).then(res => {
        if (res.retdata != null) {
          res.retdata.forEach(function (item, index) {
            let type = 0;
            let nickname = item.projname;
            if (item.msgflag == 2) {
              type = 1;
              nickname = '我';
            }
            let message = {
              type: type,
              content: item.content,
              nickname: nickname,
              adminreadflag:item.adminreadflag,
              sendTime: item.createtime,
            };
            this_.data.push(message);
          })
          this.getDataList();
        }
      })
    }, reconnect() {//重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      };
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        console.log("重连中")
        that.initWebSocket();
        that.lockReconnect = false;
      }, 5000);
    },
    reset() {//重置心跳
      var that = this;

      that.isoffline();

      //清除时间
      clearTimeout(that.timeoutObj);
      //重启心跳
      that.start();
    },
    start() { //开启心跳
      // clearTimeout(this.timer)
      if (this.timer==null){
        this.timer = setInterval(() => {
          if (this.heartbeatIntervalTime > 1) {
            this.heartbeatIntervalTime -= 1
          }else{
            this.heartbeatIntervalTime = this.$store.state.setting.heartbeatIntervalTime
            if (this.websocket.readyState === WebSocket.OPEN) {
              console.log("----------------------------->",this.websocket.readyState)
              // 发送数据
              let message = {
                clientid: this.conninfo.clientid,
                csid: this.user.userId,
                ipaddr: this.conninfo.ip,
                heartbeattime: parseTime(new Date(), "{y}-{m}-{d} {h}:{i}:{s}")
              }
              this.websocket.send(JSON.stringify(message))
              this.healthStatus = 1
              let data = {
                heartbeattime: parseTime(new Date(), "{h}:{i}:{s}"),
                type: 1
              }
              this.heartbeatList = this.heartbeatList.reverse()
              this.heartbeatList.push(data);

            } else {
              this.isOnline = false;
              this.heartbeatIntervalTime = this.$store.state.setting.heartbeatIntervalTime;
              this.healthStatus = 3
              let data = {
                heartbeattime: parseTime(new Date(), "{h}:{i}:{s}"),
                type: 0
              }
              this.heartbeatList = this.heartbeatList.reverse()
              this.heartbeatList.push(data);

              this.reconnect()
            }
          }
        }, 1000);
      }
      // let timer;
      if (this.OnlineTimeoutObj!=null){
        return
      }
  /*    this.OnlineTimeoutObj = setInterval(() => {
        if (this.websocket.readyState === WebSocket.OPEN) {
          console.log("----------------------------->",this.websocket.readyState)
          // 发送数据
          let message = {
            clientid: this.conninfo.clientid,
            csid: this.user.userId,
            ipaddr: this.$options.filters.ipFilter(this.conninfo.ip),
            heartbeattime: parseTime(new Date(), "{y}-{m}-{d} {h}:{i}:{s}")
          }
          this.websocket.send(JSON.stringify(message))
          this.healthStatus = 1
          let data = {
            heartbeattime: parseTime(new Date(), "{h}:{i}:{s}"),
            type: 1
          }
          this.heartbeatList = this.heartbeatList.reverse()
          this.heartbeatList.push(data);

        } else {
          this.isOnline = false;
          this.heartbeatIntervalTime = this.$store.state.setting.heartbeatIntervalTime;
          this.healthStatus = 3
          let data = {
            heartbeattime: parseTime(new Date(), "{h}:{i}:{s}"),
            type: 0
          }
          this.heartbeatList = this.heartbeatList.reverse()
          this.heartbeatList.push(data);

          this.reconnect()
        }
      }, this.heartbeatIntervalTime*1000)*/

    },
    onopen() {
      console.log("open");
      //开启心跳
      this.start();
    }, logout() {
      this.$store.commit('account/setUser', null);
      this.$router.push({path: '/login'});
      this.$Bus.$emit("logout");
      this.websocket.close()
      localStorage.removeItem("token");
    },
    clear(){
      clearTimeout(this.timer)
      this.timer = null;
      clearTimeout(this.OnlineTimeoutObj)
      this.OnlineTimeoutObj = null
    },
    getQuickreply() { //获取快捷回复数据
      let data = {
        csid: this.user.userId
      }
      getQuickreply(data).then(res => {
        this.quickReplyList = res.retdata.quickReplyList;
        this.allGroupList = res.retdata.allGroupList;
      })
    }, addGroup() {//添加分组
      this.dialogGroupVisible = true;
      this.dialogStatus = 'create';
      this.GroupForm = {
        groupid: null,
        groupname: null
      }
    }, addQuickReply() { //添加快捷语
      this.QuickReplyForm = {
        id: null,
        content: null,
        title: null
      }
      this.dialogQuickReplyVisible = true;
      this.dialogStatus = 'create';
    }, createGroupData() {//添加分组
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.GroupForm.csid = this.user.userId;
          addorupdategroup(this.GroupForm).then(res => {
            this.$message.success(res.retDesc);
            this.dialogGroupVisible = false;
            this.getQuickreply();
          });
        }
      });
    }, updateDate() {//修改
      this.selectTree = this.$refs.tree.getCheckedNodes();
      let length = this.selectTree.length;
      if (length == 0) {
        this.$message.info("请勾选需要修改内容");
        return false;
      }
      if (length > 1) {
        this.$message.info("最多只能勾选一个内容");
        return false;
      }
      let select = this.selectTree[0];
      if (select.layer == 1) {
        this.dialogGroupVisible = true;
        this.dialogStatus = 'update';
        this.GroupForm = {
          groupid: select.eid,
          groupname: select.label
        }
      } else {
        this.dialogQuickReplyVisible = true;
        this.dialogStatus = 'update';
        this.QuickReplyForm = {
          id: select.eid,
          content: select.content,
          title: select.label,
          groupid: select.groupid
        }
      }
    }
    , updateGroupData() {//修改分组
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.GroupForm.csid = this.user.userId;
          addorupdategroup(this.GroupForm).then(res => {
            this.$message.success(res.retDesc);
            this.dialogGroupVisible = false;
            this.getQuickreply();
          });
        }
      });
    }, createQuickReplyData() {//添加快捷常用语
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          addorupdateQuickreplyInfo(this.QuickReplyForm).then(res => {
            this.$message.success(res.retDesc);
            this.dialogQuickReplyVisible = false;
            this.getQuickreply();
          });
        }
      })
    }, updateQuickReplyData() {//修改添加快捷常用语
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          addorupdateQuickreplyInfo(this.QuickReplyForm).then(res => {
            this.$message.success(res.retDesc);
            this.dialogQuickReplyVisible = false;
            this.getQuickreply();
          });
        }
      });
    }, deleteDate() {//删除
      this.selectTree = this.$refs.tree.getCheckedNodes();
      let length = this.selectTree.length;
      if (length == 0) {
        this.$message.info("请勾选需要修改内容");
        return false;
      }
      if (length > 1) {
        this.$message.info("最多只能勾选一个内容");
        return false;
      }
      let select = this.selectTree[0];
      if (select.layer == 1) {
        let data = {
          groupid: select.eid
        }
        deleteGroup(data).then(res => {
          this.$message.success(res.retDesc);
          this.getQuickreply();
        });
      } else {
        let data = {
          id: select.eid
        }
        deleteQuickreplyInfo(data).then(res => {
          this.$message.success(res.retDesc);
          this.getQuickreply();
        });
      }
    }, handleClick(tab, event) {
      console.log(tab, event);
      if (tab.name==='second'){
        this.$refs.leaveMessage.getList()
      }
    }, filterNode(value, data) {
      if (!value) {
        return true;
      }
      return data.label.indexOf(value) !== -1;
    }, handleNodeClick(data) {
      if (data.layer == 2) {
        this.content = null;
        let message = {
          type: 1,
          serviceflag: 1,
          connid: this.connid,
          userId: this.userId,
          content: data.content,
          projid: this.projid,
          nickname: '我',
          msgtype: 2,
          clientid: this.conninfo.clientid,
          toUserId: this.toUserId,
          sendTime: new Date().getTime(),
        };
        if (this.current != null) {
          sendOneWebSocket(message).then(res => {
          })
          this.messageConfig.data.push(message);
        }
      }
    }, selfScrollDown() {
      this.$refs['selfScrollbar'].wrap.scrollTop = this.$refs['selfScrollbar'].wrap.scrollHeight;
    }, imgView(src) {
      this.imgsVisible = true;
      this.imgs = src;
    }, moveout() {//迁出
      var self = this;
      this.$confirm('确定签出服务吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        self.lineStatus = false;
        self.setOncloseMessage()
        self.timeoutObj && clearTimeout(self.timeoutObj);
        self.framecolor = '#d4d0d0';
        self.getDataList();
      });
    }, movein() {//迁入
      this.$confirm('确定签入服务吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.lineStatus = true;
        this.initWebSocket();
        this.framecolor = '#da424e';
      });
    }, updateUserInfo() {
      this.identityConfig.show = true;
      this.identityConfig.form.address = this.conninfo.useraddress;
      this.identityConfig.form.gender = this.conninfo.gender;
      this.identityConfig.form.age = this.conninfo.age;
      this.identityConfig.form.nicename = this.conninfo.nicename;
      this.identityConfig.form.phone = this.conninfo.phone;
      this.identityConfig.form.clientid = this.conninfo.clientid;
      this.identityConfig.form.id = this.conninfo.userinfoid;
      this.identityConfig.form.remarks = this.conninfo.remarks;
    }, onSubmitClick() {
      this.$refs['identityForm'].validate().then(() => {
        addOrUpdateUserInfo(this.identityConfig.form).then(res => {
          this.$message.success('提交成功');
        })
        this.identityConfig.show = false;
        this.getDataList();
        this.conninfo.useraddress = this.identityConfig.form.address;
        this.conninfo.gender = this.identityConfig.form.gender;
        this.conninfo.age = this.identityConfig.form.age;
        this.conninfo.nicename = this.identityConfig.form.nicename;
        this.conninfo.phone = this.identityConfig.form.phone;
        this.conninfo.remarks = this.identityConfig.form.remarks;
      });
    }, updateOnlineStatus(e) {
      const {type} = e;
      this.onLine = type === 'online';
    }, isoffline() {
      clearInterval(interval);
      let this_ = this;
      var interval = setInterval(() => {
        if (!this_.onLine) {
          if (this_.lineStatus) {
            let data = {
              heartbeattime: parseTime(new Date(), "{h}:{i}:{s}"),
              type: 0
            }
            this_.heartbeatList = this_.heartbeatList.reverse()
            this_.heartbeatList.push(data);
            this_.lineStatus = false;
            this_.websocket.close();
            this_.timeoutObj && clearTimeout(self.timeoutObj);
            this_.framecolor = '#d4d0d0';
            setTimeout(function () {
              this_.getDataList();
            }, 2000)
          }
        } else {
          if (!this_.onLine && !this_.lineStatus) {
            this_.lineStatus = true;
            this_.initWebSocket();
            this_.framecolor = '#da424e';
          }
        }
      }, this_.timeout);
    },
    //心跳检测
    startHeartbeat() {

    }
  },

}
</script>
<style scoped lang="scss">
.server-container ::v-deep {
  .header {
    color: #333;
    text-align: center;
    line-height: 60px;
  }

  .task-left {
    width: 55%;
    padding: 0;
    $editor-height: 200px;

    .custominfo {
      position: absolute;
      background-color: #f3eded;
      height: 30px;
      width: 100%;
      line-height: 30px;
      color: #999898;
    }

    .message-record {
      background-color: white;
      margin-top: 30px;
      height: calc(100% - #{$editor-height});
      padding-left: 20px;

      .message-row {
        box-sizing: border-box;
        margin-bottom: 15px;

        &.self {
          text-align: right;
          padding-right: 20px;

          .message-content {
            &:before {
              left: 100%;
              border: 5px solid transparent;
              border-left-color: #F8f8f8;
            }
          }
        }

        .message-content {
          box-sizing: border-box;
          padding: 0 10px;
          display: inline-block;
          line-height: 2.5;
          border: 1px #eee solid;
          background-color: #F8f8f8;
          border-radius: 3px;
          position: relative;
          margin-top: 5px;

          &:before {
            box-sizing: border-box;
            content: '';
            top: 10px;
            position: absolute;
            right: 100%;
            border: 6px solid transparent;
            border-right-color: #F8f8f8;
          }
        }
      }
    }

    .editor {
      height: $editor-height;
      border-radius: unset;

      .ProseMirror {
        min-height: 100%;
      }

      & > .el-tiptap-editor__content {
        padding: 10px;
        flex: 1;
      }

      .border-top-radius {
        border-radius: unset;
      }
    }
  }

  .task-right {
    background-color: white;
    color: #333;
    border-left: 1px solid #ebeef5;
    line-height: 40px;
    padding: 10px 10px 40px;
    height: 100%;
    position: relative;
    width: 20%;
  }

  .el-aside {
    background-color: white;
    color: #333;
    height: 100%;
    line-height: 20px;
    font-size: 15px;
    border-right: 1px solid #d9d8d8;
  }

  .el-aside li a {
    padding: 5px 10px;
    display: flex;
    background-color: white;
    border-bottom: 1px solid #bfbfbf;

  }

  .active {
    color: #ef8709;
  }
}

@keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}

.headerBox {
  animation: fade 600ms infinite;
  -webkit-animation: fade 600ms infinite;
}

</style>
<style>

.online {
  display: flex;
  align-items: center;
}

.mar-r11 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.green {
  background-color: green;
}

.yellow {
  background-color: yellow;
}


.server-container .el-submenu__title {
  height: 40px !important;
  line-height: 40px !important;
  padding-left: 10px !important;
  font-size: 13px !important;
  border-bottom: 1px solid #e2e1e1;
  border-top: 1px solid #e2e1e1;
}

.server-container .el-submenu .el-menu-item {
  height: 40px;
  line-height: 36px;
  padding: 0px;
  padding: 0px 10px !important;
  font-size: 12px;
  border-bottom: 1px solid #e2dfdf;
}

.server-container .el-menu-item-group__title {
  padding: 0px;
  line-height: normal;
  font-size: 12px;
  color: #909399;
}

.server-container .el-aside li div {
  background-color: white;
}

.editor {
  height: 170px !important;
}

.el-tabs__content {
  height: calc(100% - 15px);
}

.dialog {
  overflow-y: scroll;
}

.server-container .dialog::-webkit-scrollbar {
  width: 1px;
  border-radius: 8px;
}

/* 滚动条颜色 */
.server-container .dialog::-webkit-scrollbar-track {
  background-color: #555;
}

.server-container .dialog::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #333;
}

.server-container .dialog {
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;
  -ms-scrollbar-track-color: transparent;
}

.server-container .el-scrollbar__wrap {
  overflow-x: hidden;
}

.server-container .msgCount {
  background-color: red !important;
  color: white;
  margin: 12px 10px;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  line-height: 15px;
  text-align: center;
  position: absolute;
}

.server-container .notline {
  color: #bfbfbf;
}

.server-container .top-btn {
  background-color: transparent;
  color: white;
  border: 0px;
  height: 30px;
  padding: 0px;
  line-height: 41px;
  width: 50px;
}

.server-container .el-button:focus {
  color: white;
  border-color: #c6e2ff;
  background-color: transparent;
}

.server-container .el-button:hover {
  color: #cbc9c9;
  border-color: #c6e2ff;
  background-color: transparent;
}

.server-container .el-form-item__content {
  margin-left: 100px !important;
}

.el-main {
  padding: 0px;
}

.noList {
  color: #afb3bf;
  font-size: 13px;
  text-align: center;
  padding-top: 10px;
}

.el-tab-pane {
  overflow-y: auto;
  height: 100%
}

.customerInfo .el-form-item__content {
  margin-left: 10px !important;
}

.server-container .el-submenu__title {
  height: 40px !important;
  line-height: 40px !important;
  padding-left: 10px !important;
  font-size: 13px !important;
  border-bottom: 1px solid #e2e1e1;
  border-top: 1px solid #e2e1e1;
  background-color: #fdfdfd !important;
}
</style>
