<template>
  <div class="app-container">

    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
      size="mini"
    >
      <el-table-column label="项目编号" width="100"  align="center">
        <template slot-scope="{row}">
          <span>{{ row.projid }}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目名称"  align="center">
        <template slot-scope="{row}">
          <span>{{ row.projname }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" width="130">
        <template slot-scope="{row}">
          <span>{{ row.createtime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import {getProjectByCsid} from "@/apis/project";
  import { parseTime } from '@/utils'
  export default {
    name: 'projectinfo',
    components: {
      pagination: () => import('@/components/common/PagingPlug'),
    },
    filters: {
      parseTime:parseTime
    },
    data() {
      return {
        tableKey: 0,
        list: null,
        total: 0,
        listLoading: true,
      }
    },
    created() {

    },mounted() {
      this.getList()
    },
    methods: {
      getList() {
        this.listLoading = true
        let data={
          csid:this.user.userId
        }
        getProjectByCsid(data).then(res => {
        const {data = [], count = 0} = res;
        this.list=data.dataList;
        this.total=count;
        this.listLoading = false;
        this.$emit('projectList',this.total)
        })
      },
      formatJson(filterVal) {
        return this.list.map(v => filterVal.map(j => {
          if (j === 'timestamp') {
            return parseTime(v[j])
          } else {
            return v[j]
          }
        }))
      }
    },computed:{
  }
  }
</script>

<style>
.filter-item {
  margin-left:5px;
}
.el-dialog__footer {
  text-align: center;
}
.el-tooltip__popper {
  max-width: 800px;

}

</style>
