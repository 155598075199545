<template>
  <div>{{ time }}</div>
</template>

<script>
  export default {
    name: "Timer",
    props: {
      start: {
        type: Number,
        default: new Date().getTime(),
      },
    },
    data() {
      return {
        time: 0,
      }
    },
    mounted() {
      this.init();
      this.time = this.formatDate2(new Date().getTime() - this.start);
    },
    methods: {
      init() {
        setInterval(() => {
          this.time = this.formatDate2(new Date().getTime() - this.start);
        }, 1000);
      },
      formatDate(mss) {
        let days = Math.floor(mss / (1000 * 60 * 60 * 24));
        let hours = Math.floor((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((mss % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.round((mss % (1000 * 60)) / 1000);
        let retDate = '';
        if (days != 0) {
          retDate = days + "天";
        }
        if (hours != 0) {
          retDate += hours + "小时";
        }
        if (minutes != 0) {
          retDate += minutes + "分钟";
        }
        if (seconds != 0) {
          retDate += seconds + "秒";
        }
        return retDate;
      },
      formatDate2(mss){
        // let mss = row.disconnecttime - row.createtime;

        const seconds = Math.floor((mss / 1000) % 60);
        const minutes = Math.floor((mss / (1000*60)) % 60)
        const hours = Math.floor((mss / (1000*60*60)) % 24)
        let timeString = '';
        if (hours > 0){
          timeString +=hours + 'h'
        }
        if (minutes > 0 || (hours>0 && seconds >0)){
          timeString += minutes + 'm'
        }

        if (seconds >0 || (minutes >0 && hours ===0)){
          timeString +=seconds + 's';
        }
        return timeString;
      }
    }
  }
</script>

<style scoped>

</style>
