<template>
  <div class="app-container">
    <div class="filter-container" style="margin-bottom: 10px">
      <el-input v-model="listQuery.projname" size="mini" placeholder="项目名" style="width: 30%;" class="filter-item" @keyup.enter.native="handleFilter" />
      <el-select v-model="listQuery.status" size="mini" placeholder="状态" clearable style="width: 90px" class="filter-item">
        <template v-for="status in statusStatus">
          <el-option :label="status.name" :value="status.value"></el-option>
        </template>
      </el-select>
      <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="handleFilter">
        查询
      </el-button>
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-refresh-left" @click="refresh">
        刷新
      </el-button>
    </div>

    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
      size="mini"
    >
      <el-table-column label="序号" width="50"
                       type="index"
                       align="center">
      </el-table-column>
      <el-table-column label="项目名称" width="130px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.projname }}</span>
        </template>
      </el-table-column>
      <el-table-column label="留言内容" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span>{{ row.content}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" width="60px">
        <template slot-scope="{row}">
          <span>{{statusType(row.status)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="时间" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span>{{formatDate(row.createtime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="60px" align="center">
        <template slot-scope="{row}">
          <span><a @click="dealLeave(row)"><i class="el-icon-edit-outline"></i></a></span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="600px">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px">
        <el-form-item label="留言回复" prop="content">
          <el-input v-model="temp.content" type="text" style="width: 300px" placeholder="请输入回复内容"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='reply'?replyData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>

    <el-dialog :title="textLeaveDetailMap[dialogLeaveDetailStatus]" :visible.sync="dialogLeaveDetailsVisible" width="600px">
      <div slot="title" style="border-bottom: 1px solid #999999">
        <span style="font-size: 16px;font-weight: 600">用户留言处理</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <div style="border-bottom: 1px solid #b9b7b7">
          <el-form ref="dataForm" :model="leaveInfo" label-position="left" label-width="140px" style="margin-left:50px;text-align: left" size="mini">
            <el-form-item label="项目名称:" prop="content" >
              <div>{{ leaveInfo.projname}}</div>
            </el-form-item>
            <el-form-item label="用户手机号:" prop="content">
              <div>{{ leaveInfo.mobile}}</div>
            </el-form-item>
            <el-form-item label="邮箱/QQ号码/微信:" prop="content">
              <div>{{ leaveInfo.email}}</div>
            </el-form-item>
            <el-form-item label="留言时间:" prop="content">
              <div>{{ dataFromat(leaveInfo.createtime)}}</div>
            </el-form-item>
            <el-form-item label="留言内容:" prop="content">
              <div>{{ leaveInfo.content}}</div>
            </el-form-item>
          </el-form>
        </div>
        <div style="margin-top: 15px;display: flex;">
          <div  class="message">

            <div style="display: flex;flex-direction: row">
              <p style="font-size: 15px;"><span >留言状态: </span> <span>{{ statusType(leaveInfo.status) }}</span>
              <div style="margin-left: 100px">
                <el-button type="primary" v-if="leaveInfo.status !='3'" size="mini" @click="handleReply(leaveInfo)">
                  回复
                </el-button>
                <el-button v-if="leaveInfo.status=='0'" size="mini" type="success" @click="handleModifyStatus(leaveInfo,'1')">
                  标记处理中
                </el-button>
                <el-button v-if="leaveInfo.status=='1'" size="mini" type="primary" @click="handleModifyStatus(leaveInfo,'2')">
                  标记完成
                </el-button>
                <el-button size="mini" v-if="leaveInfo.status !='3'" type="danger" @click="handleModifyStatus(leaveInfo,'3')">
                  标记废除
                </el-button>
                <el-button @click="dialogLeaveDetailsVisible = false" size="mini">
                  取消
                </el-button>
              </div>
            </div>
           <div ><span style="font-size: 15px;">处理内容: </span>
             <div >
               <el-table :data="handlerContent.slice(-3)" height="180px">
                 <el-table-column type="index"  label="序号" align="center" ></el-table-column>
                 <el-table-column  property="content" label="处理内容" align="center" ></el-table-column>
                 <el-table-column  property="createtime" label="处理时间" align="center" >
                   <template slot-scope="{row}">
                     {{formatDate(row.createtime) }}
                   </template>
                 </el-table-column>
               </el-table>
             </div>
             <el-popover
             placement="bottom"
             width="380"
             trigger="click">
               <div >
                 <el-table :data="handlerContent" height="180px">
                   <el-table-column type="index" width="50" label="序号" align="center" ></el-table-column>
                   <el-table-column width="150px" property="content" label="处理内容" align="center" ></el-table-column>
                   <el-table-column width="150px" property="createtime" label="处理时间" align="center" >
                     <template slot-scope="{row}">
                       {{formatDate(row.createtime) }}
                     </template>
                   </el-table-column>
                 </el-table>
               </div>
               <span slot="reference" style="cursor: pointer"><el-tag size="mini" v-if="handlerContent.length>3">查看</el-tag></span>
             </el-popover>
             </div>
          </div>
        </div>

      </div>
    </el-dialog>

  </div>
</template>

<script>
  import {getCsServiceLeave,dealWithLeave,replyLeave,getHandlerContent} from "@/apis/leave";
  import { parseTime } from '@/utils'
  const statusOptions = [
    { key: '0', display_name: '未处理' },
    { key: '1', display_name: '处理中' },
    { key: '2', display_name: '已处理' },
    { key: '3', display_name: '已废除' },
  ]
  const calendarTypeKeyValue = statusOptions.reduce((acc, cur) => {
    acc[cur.key] = cur.display_name
    return acc
  }, {})
  export default {
    name: 'projectinfo',
    components: {
      pagination: () => import('@/components/common/PagingPlug'),
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'info',
          deleted: 'danger'
        }
        return statusMap[status]
      },
      typeFilter(type) {
        return calendarTypeKeyValue[type]
      },
      parseTime:parseTime
    },
    data() {
      return {
        tableKey: 0,
        list: null,
        total: 0,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 20,
          projname:'',
          status:0,
          flaguserid:null
        },
        statusOptions: ['published', 'draft', 'deleted'],
        showReviewer: false,
        handlerContent:[],
        temp: {
          lmid: null,
          csid: '',
          content: ''
        },
        dialogFormVisible: false,
        dialogStatus: '',
        textMap: {
          reply: '留言回复'
        },
        leaveInfo:{
          status:0
        },
        dialogLeaveDetailsVisible: false,
        dialogLeaveDetailStatus: '',
        textLeaveDetailMap: {
          reply: '留言回复'
        },
        dialogPvVisible: false,
        pvData: [],
        rules: {
          content: [{ required: true, message: '请输入回复内容', trigger: 'change' }]
        },
        downloadLoading: false
      }
    },
    created() {

    },mounted() {
      this.getList()
    },
    methods: {
      getList() {
        this.listLoading = true
        this.listQuery.csid=this.user.userId
        getCsServiceLeave(this.listQuery).then(res => {
        const {retdata = [], totalCount = 0} = res;
        this.list=retdata;
        this.total=totalCount;
        this.$emit("leaveMessage",this.total)
        this.listLoading = false;
        })
      },
      statusType(type){
        if (type===0 || type==='0'){
          return "未处理"
        }else if (type===1 || type==='1'){
          return "处理中"
        }else if (type===2 || type==='2'){
          return "已处理"
        }else{
          return '已废除'
        }
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },refresh(){
        this.listQuery.page = 1
        this.getList()
      }, handleModifyStatus(row, status) {
        this.temp = Object.assign({}, row)
        var lmid=this.temp.lmid;
        var flaguserid=this.user.userId;
        var flagtype=2;
        dealWithLeave({lmid,status,flaguserid,flagtype}).then(res =>{
          this.$message.success(res.retDesc);
          row.status = status
        })
      },
      dataFromat(mss){

        const seconds = Math.floor((mss / 1000) % 60);
        const minutes = Math.floor((mss / (1000*60)) % 60)
        const hours = Math.floor((mss / (1000*60*60)) % 24)
        let timeString = '';
        if (hours > 0){
          timeString +=hours + ':'
        }
        if (minutes > 0 || (hours>0 && seconds >0)){
          timeString += minutes + ':'
        }

        if (seconds >0 || (minutes >0 && hours ===0)){
          timeString +=seconds + '';
        }
        return timeString;
      },
      formatJson(filterVal) {
        return this.list.map(v => filterVal.map(j => {
          if (j === 'timestamp') {
            return parseTime(v[j])
          } else {
            return v[j]
          }
        }))
      },handleReply(row) {
        this.temp = Object.assign({}, row)
        this.temp.content='';
        this.dialogStatus = 'reply'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },replyData(){
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
              this.temp.csid=this.user.userId
              console.log(this.temp)
              replyLeave(this.temp).then(res =>{
                this.$message.success(res.retDesc);
                this.dialogFormVisible=false;
                const params = {
                  lmid: this.temp.lmid
                }
                if (this.temp.status == 0){
                  this.leaveInfo.status=1

                  this.getList()
                }
                getHandlerContent(params).then(res=>{
                  this.handlerContent = res.retdata
                })
              });
          }
        })
      },dealLeave(row){//留言处理
        const params = {
          lmid: row.lmid
        }
        getHandlerContent(params).then(res=>{
          this.handlerContent = res.retdata
        })
       this.dialogLeaveDetailsVisible=true;
       this.leaveInfo=row;
      },
      formatDate(params){
        var data = new Date(params)
       /* return `${data.getFullYear()}-${('0' + (data.getMonth() + 1)).slice(-2)}-
        ${('0' + data.getDate()).slice(-2)} ${('0' + data.getHours()).slice(-2)}:
        ${('0' + data.getMinutes()).slice(-2)}`*/

         return `${data.getFullYear()}-${('0' + (data.getMonth() + 1)).slice(-2)}-
     ${('0' + data.getDate()).slice(-2)} `
      }
    },computed:{
    statusStatus() {
      return [
        {name: '全部', value: null},
        {name: '未处理', value: 0},
        {name: '处理中', value: 1},
        {name: '已处理', value: 2},
        {name: '已废除', value: 3}
      ]
    },
  }
  }
</script>

<style>
.filter-item {
  margin-left:5px;
}
.el-dialog__footer {
  text-align: center;
}
.el-tooltip__popper {
  max-width: 800px;

}
.message{
  display: flex;
  flex-direction: column;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
